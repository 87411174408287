/*
=============== 
Variables
===============
*/

/* :root { */
/* dark shades of primary color*/
/* --clr-primary-1: hsl(2.3, 86%, 17%);
  --clr-primary-2: hsl(2.3, 77%, 27%);
  --clr-primary-3: hsl(2.3, 72%, 37%);
  --clr-primary-4: hsl(2.3, 63%, 48%); */
/* primary/main color */
/* --clr-primary-5: hsl(2.3, 100%, 59.2%); */
/* lighter shades of primary color */
/* --clr-primary-6: hsl(2.3, 89%, 70%);
  --clr-primary-7: hsl(2.3, 90%, 76%);
  --clr-primary-8: hsl(2.3, 86%, 81%);
  --clr-primary-9: hsl(2.3, 90%, 88%);
  --clr-primary-10: hsl(2.3, 100%, 96%);
  --clr-primary-white: hsl(15, 100%, 97.6%); */
/* darkest grey - used for headings */
/* --clr-grey-1: hsl(2.3, 61%, 16%);
  --clr-grey-2: hsl(2.3, 39%, 23%);
  --clr-grey-3: hsl(2.3, 34%, 30%);
  --clr-grey-4: hsl(2.3, 28%, 39%); */
/* grey used for paragraphs */
/* --clr-grey-5: hsl(2.3, 22%, 49%);
  --clr-grey-6: hsl(2.3, 23%, 60%);
  --clr-grey-7: hsl(2.3, 27%, 70%);
  --clr-grey-8: hsl(2.3, 31%, 80%);
  --clr-grey-9: hsl(2.3, 33%, 89%);
  --clr-grey-10: hsl(2.3, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: #ff372f;
  --clr-red-light: hsl(16.2, 71%, 66%);
  --clr-orange: hsl(30.6, 89%, 57.3%);
  --clr-yellow: hsl(50, 93.5%, 69.8%);
  --clr-green-dark: hsl(51.4, 57.9%, 47.5%);
  --clr-green-light: hsl(87.7, 81.6%, 72.4%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px; */
/* } */

/* :root {
  --hero1: url(./images/sample-R2-svgHeroYellowv2.svg);
  --hero2: url(./images/sample-R2-svgHeroBluev1.svg);
} */

.light-theme {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(2.3, 86%, 17%);
  --clr-primary-2: hsl(2.3, 77%, 27%);
  --clr-primary-3: hsl(2.3, 72%, 37%);
  --clr-primary-4: hsl(2.3, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(2.3, 100%, 59.2%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(2.3, 89%, 70%);
  --clr-primary-7: hsl(2.3, 90%, 76%);
  --clr-primary-8: hsl(2.3, 86%, 81%);
  --clr-primary-9: hsl(2.3, 90%, 88%);
  --clr-primary-10: hsl(2.3, 100%, 96%);
  --clr-primary-white: hsl(15, 100%, 97.6%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(2.3, 61%, 16%);
  --clr-grey-2: hsl(2.3, 39%, 23%);
  --clr-grey-3: hsl(2.3, 34%, 30%);
  --clr-grey-4: hsl(2.3, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(2.3, 22%, 49%);
  --clr-grey-6: hsl(2.3, 23%, 60%);
  --clr-grey-7: hsl(2.3, 27%, 70%);
  --clr-grey-8: hsl(2.3, 31%, 80%);
  --clr-grey-9: hsl(2.3, 33%, 89%);
  --clr-grey-10: hsl(2.3, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: #ff372f;
  --clr-red-light: hsl(16.2, 71%, 66%);
  --clr-orange: hsl(30.6, 89%, 57.3%);
  --clr-yellow: hsl(50, 93.5%, 69.8%);
  --clr-green-dark: hsl(51.4, 57.9%, 47.5%);
  --clr-green-light: hsl(87.7, 81.6%, 72.4%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  /* --hero: var(--hero1); */
}

.dark-theme {
  /* light shades of primary color*/
  --clr-primary-1: hsl(2.3, 90%, 88%);
  --clr-primary-2: hsl(2.3, 86%, 81%);
  --clr-primary-3: hsl(2.3, 90%, 76%);
  --clr-primary-4: hsl(2.3, 89%, 70%);
  /* primary/main color */
  --clr-primary-5: hsl(2.3, 100%, 59.2%);
  /* darker shades of primary color */
  --clr-primary-6: hsl(2.3, 63%, 48%);
  --clr-primary-7: hsl(2.3, 72%, 37%);
  --clr-primary-8: hsl(2.3, 77%, 27%);
  --clr-primary-9: hsl(2.3, 86%, 17%);
  --clr-primary-10: hsl(2.3, 100%, 10%);
  --clr-primary-white: hsl(15, 100%, 5%);
  /* lightest grey - used for headings */
  --clr-grey-1: hsl(2.3, 36%, 96%);
  --clr-grey-2: hsl(2.3, 33%, 89%);
  --clr-grey-3: hsl(2.3, 31%, 80%);
  --clr-grey-4: hsl(2.3, 27%, 70%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(2.3, 23%, 60%);
  --clr-grey-6: hsl(2.3, 22%, 49%);
  --clr-grey-7: hsl(2.3, 28%, 39%);
  --clr-grey-8: hsl(2.3, 34%, 30%);
  --clr-grey-9: hsl(2.3, 39%, 23%);
  --clr-grey-10: hsl(2.3, 61%, 16%);
  --clr-white: #222;
  --clr-red-dark: hsl(16.2, 71%, 66%);
  --clr-red-light: #ff372f;
  --clr-orange: hsl(309.9, 100%, 26.3%);
  --clr-yellow: hsl(331.7, 100%, 45.7%);
  --clr-green-dark: hsl(205.9, 95.3%, 42%);
  --clr-green-light: hsl(210.5, 100%, 11.2%);
  --clr-black: #fff;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  /* --hero: var(--hero2); */
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  /* transition: var(--transition); */
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-black);
  font-size: 1rem;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  p {
    font-size: 1.25rem;
  }
}

.btn {
  font-size: 2rem;
  padding: 0.25rem 0.25rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: var(--clr-orange);
  background: none;
  cursor: pointer;
  transition: var(--transition);
  outline: none;
  align-items: center;
  display: flex;
}
.btn:hover {
  background: transparent;
  outline: none;
  color: var(--clr-red-dark);
}

/* Nav styling  */

.nav {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--clr-white);
  position: sticky;
  top: 0;
  transition: var(--transition);
  transform: translate(0%);
  z-index: 1;
}

.hide-nav {
  transition: var(--transition);
  transform: translate(-100%);
}

.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: flex;
  width: 15vh;
}

.bounce {
  transition: var(--transition);
  animation: bounce 3s ease-in-out infinite;
}

.toggle {
  font-size: 2.5rem;
  padding: 0 0;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

/* side bar styling */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 600px;
  height: 100%;
  background: var(--clr-white);
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  box-shadow: var(--clr-red-dark);
  transition: var(--transition);
  transform: translate(-100%);
  z-index: 2;
}
.show-sidebar {
  transform: translate(0);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  z-index: 2;
}

.close-btn {
  font-size: 1.75rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  transition: var(--transition);
  cursor: pointer;
  color: var(--clr-orange);
  margin-top: 0.2rem;
  outline: none;
}
.close-btn:hover {
  color: var(--clr-red-light);
  cursor: pointer;
}
.logo {
  justify-self: center;
  /* height: 40px; */
  width: 15vh;
}

.link a {
  display: flex;
  align-items: center;
  font-size: 2rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
}

.link:hover {
  background: var(--clr-grey-10);
  color: var(--clr-grey-2);
}
.link svg {
  font-size: 2rem;
  color: var(--clr-grey-5);
  margin-right: 1rem;
  transition: var(--transition);
}
.link:hover svg {
  color: var(--clr-grey-4);
}
.social-icons {
  justify-self: center;
  display: flex;
  padding-bottom: 2rem;
}
.social-icons a {
  font-size: 3rem;
  margin: 0 0.5rem;
  color: var(--clr-primary-5);
  transition: var(--transition);
}
.social-icons a:hover {
  color: var(--clr-primary-1);
}

/* Hero styling */

/* .hero::before,
::after {
  content: '';
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background: var(--hero) center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  transition: opacity 250ms;
} */

.hero {
  /* background: var(--hero) center center;*/
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 600px;
  margin-top: -5rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  /* z-index: -3; */
}
.hero-center {
  width: 85vw;
  max-width: var(--max-width);
  display: grid;
  align-items: center;
  margin-top: 5rem;
}

.hero-info {
  background: var(--clr-white);
  padding: 2rem 1.5rem;
  border-radius: var(--radius);
}

.hero-info h1 {
  text-transform: none;
  max-width: 500px;
  margin-bottom: 2rem;
}
.hero-info button {
  max-width: 35em;
  line-height: 1.8;
}

.front {
  z-index: 2;
}

/* accordion styles */

.accordion-container {
  /* transition: all 1s ease-in-out; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 2px groove var(--clr-green-light);
}

.accordion {
  height: 3rem;
  background: var(--clr-orange);
  align-items: center;
  display: flex;
  /* border-bottom: 2px groove var(--clr-green-light); */
  justify-content: space-between;
}

.accordion:hover {
  background: var(--clr-green-dark);
  transition: var(--transition);
  cursor: pointer;
}

.accordion-title {
  margin-left: 2rem;
  display: flex;
  color: var(--clr-grey-1);
  margin-bottom: 0rem;
}

.accordion-text {
  margin: 0 1rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  color: var(--clr-primary-1);
  transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  overflow: hidden;
  font-size: 1.25rem;
}

.arrow-btn {
  background: transparent;
  outline: none;
  border: none;
  font-size: 2rem;
  display: flex;
  color: var(--clr-yellow);
  margin-right: 1rem;
  transition: transform 0.2s ease;
  display: flex;
}

.arrow-btn:hover {
  color: var(--clr-red-dark);
  transition: var(--transition);
  cursor: pointer;
}

.rotate {
  transform: rotate(90deg);
}

@media screen and (min-width: 800px) {
  .accordion,
  .accordion-text {
    padding: 0 10rem;
  }

  .arrow-btn {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 1000px) {
  .accordion,
  .accordion-text {
    padding: 0 20rem;
  }

  .arrow-btn {
    margin-right: 2rem;
  }
}

/* Home Page Blog List Styling */
.home-blog-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
}
.blog-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.blog-list {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: groove 1px var(--clr-green-light);
}

.blog {
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 968px;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: groove 1px var(--clr-green-light);
}

.sort-bar {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  width: 90%;
}

.sort-bar label {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1rem;
}

.sort-bar select {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-right: 1rem;
  background: var(--clr-white);
  color: var(--clr-black);
}

.entry-type-name {
  font-weight: 300;
  color: var(--clr-green-dark);
  letter-spacing: var(--spacing);
}

.blog-title {
  font-size: 2rem;
  margin-top: 2rem;
}

.quote-container {
  display: flex;
  justify-self: center;
  padding: 1rem;
  flex-direction: row;
}

.blog-text {
  display: flex;
  color: var(--clr-primary-1);
  transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  font-size: 1rem;
}

.blog-link-container {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-link {
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  color: var(--clr-orange);
  margin-left: 1.5rem;
}

.blog-link:hover {
  background: transparent;
  outline: none;
  color: var(--clr-red-dark);
  transition: var(--transition);
}

.blog-link svg {
  font-size: 2rem;
  margin-right: 1rem;
  transition: var(--transition);
}
.blog-link:hover svg {
  color: var(--clr-red-dark);
}

.blog-image {
  width: 100%;
  max-width: 1000px;
  align-self: center;
  border: solid 1px var(--clr-green-light);
  border-radius: var(--radius);
}

@media screen and (min-width: 800px) {
  .home-blog-container {
    width: 70vw;
    align-items: center;
    margin: 0 auto;
  }

  .sort-bar {
    justify-content: center;
  }

  .sort-bar label {
    flex: none;
  }

  .sort-bar select {
    width: 20%;
    flex: none;
  }

  .blog-list {
    width: 63vw;
  }
}

/* commment styling  */

.comment-container {
  width: 80vw;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.comment-form-container {
  width: 80vw;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.comment-div {
  margin: 1rem;
  border-bottom: 1px groove var(--clr-green-light);
}

.comment-title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: var(--clr-orange);
  font-weight: 600;
}

.comment-date {
  font-size: 0.75rem;
  color: var(--clr-green-dark);
}

.comment {
  padding: 0 1rem;
}

.comment-info {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  max-width: 600px;
  font-size: 1rem;
}

.comment-info input,
textarea {
  font-size: 1rem;
  transition: var(--transition);
  background: var(--clr-white);
  color: var(--clr-black);
  box-shadow: none;
  outline: none;
  border: 0px;
  border-bottom: 1px solid var(--clr-black);
  margin: 1rem 0;
}

@media screen and (min-width: 800px) {
  .comment-title {
    font-size: 1.5rem;
  }
}

/* contact form styling  */

.form-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  font-size: 2rem;
}

.contact-info input,
textarea {
  font-size: 2rem;
  transition: var(--transition);
  background: var(--clr-white);
  color: var(--clr-black);
  box-shadow: none;
  outline: none;
  border: 0px;
  border-bottom: 1px solid var(--clr-black);
  margin-bottom: 1rem;
}

.contact-received {
  text-align: center;
  font-size: 2rem;
  display: flex;
  height: 200px;
  align-items: center;
  padding: 2rem;
}

@media screen and (min-width: 800px) {
  .form-container {
    /* margin-top: 20rem; */
    width: 65vw;
    margin: 0 auto;
    padding-top: 5rem;
  }

  .contact-info label,
  input,
  textarea {
    margin-bottom: 1rem;
  }
}

/* control panel styling */

.control-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.control-title {
  align-self: center;
  display: flex;
  font-size: 2rem;
  padding: 1rem;
}

.control-btn {
  background: transparent;
  outline: 1px solid var(--clr-orange);
  border: none;
  font-size: 2rem;
  display: flex;
  color: var(--clr-yellow);
  transition: transform 0.2s ease;
  width: 50%;
  justify-content: center;
  align-self: center;
}

.progress-bar {
  height: 1rem;
  background: var(--clr-green-dark);
  margin-top: 10px;
}

/* about lawrence styling */
.profile-container {
  display: flex;
  padding: 1rem;
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
}

.img-container {
  width: 200px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.profile-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.profile-txt {
  font-size: 1rem;
}

.bold {
  font-weight: 900;
  font-size: 1.25rem;
}

.txt-center {
  text-align: center;
}

/* about this website styling */

.about-container {
  display: flex;
  padding: 1rem;
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
}

.logo-container {
  width: 200px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.small-logo-container {
  display: flex;
  flex-direction: column;
}

.small-logo {
  width: 100px;
  margin-top: 2rem;
}

@media screen and (min-width: 800px) {
  .small-logo-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  .small-logo {
    height: 100px;
    width: auto;
  }
}
/* footer styling  */

.footer {
  height: 4rem;
  display: none;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  transition: var(--transition);
  transform: translate(0%);
}

.footer-links {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  justify-content: space-around;
  font-size: 2rem;
  color: var(--clr-green-light);
}

.footer-social {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 2 auto;
  justify-content: space-around;
  font-size: 2rem;
}

.f-links svg {
  display: flex;
  font-size: 2rem;
  color: var(--clr-green-dark);
  transition: var(--transition);
}
